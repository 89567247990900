<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">客户中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学校列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ pageDatas.schoolId ? '编辑' : '新建' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="pageDatas" label-width="100px" class="demo-ruleForm form-box" size="small"
              :rules="pageDatasRules" :model="pageDatas">
              <el-form-item label="学校名称" prop="schoolName">
                <el-input v-model="pageDatas.schoolName" clearable placeholder="请输入学校名称"></el-input>
              </el-form-item>
              <el-form-item label="所在地市" prop="areaId">
                <el-cascader clearable filterable v-model="pageDatas.areaId" :options="areatreeList"
                  :props="propsarea" size="small"></el-cascader>
              </el-form-item>
              <el-form-item label="地址" prop="schoolAddress">
                <el-input v-model="pageDatas.schoolAddress" clearable placeholder="请输入地址"></el-input>
              </el-form-item>
              <el-form-item label="办公性质" prop="schoolNature">
                <el-select v-model="pageDatas.schoolNature" placeholder="请选择办公性质" size="small" clearable>
                  <el-option v-for="item in schoolNatureList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="院校分类" prop="schoolClassify">
                <el-select v-model="pageDatas.schoolClassify" placeholder="请选择院校分类" size="small" clearable>
                  <el-option v-for="item in schoolClassifyList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="在校人数" prop="schoolStudentNum">
                <el-input v-model="pageDatas.schoolStudentNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" clearable placeholder="请输入在校人数"></el-input>
              </el-form-item>
              <el-form-item label="联系人" prop="schoolPerson">
                <el-input v-model="pageDatas.schoolPerson" clearable placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="schoolMobile">
                <el-input v-model="pageDatas.schoolMobile" clearable placeholder="请输入联系电话"></el-input>
              </el-form-item>
              <el-form-item label="学校简介" prop="schoolIntroduction">
                <div ref="editor" class="editor" style="width: 100%; max-width: 1200px"></div>
              </el-form-item>
              <el-form-item label="学校logo：" prop="schoolLogoUrl" class="form-item"><el-upload :on-change="handleAvatarSuccess5"
                  :before-upload="beforeAvatarUpload2" :http-request="$requestMine" :show-file-list="false"
                  class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg"><el-image
                    :src="pageDatas.schoolLogoUrl || require('@/assets/develop.png')" fit="contain" class="imgCenter"></el-image>
                </el-upload>
              </el-form-item>
            </el-form>
            <div class="btn-box flexcc">
              <el-button class="bgc-bv" size="small" @click="cancelSave(2)">取 消</el-button>
              <el-button class="bgc-bv" size="small" @click="cancelSave(1)">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
export default {
  name: "examination_onlineTest_announcementOfManagementAddEdit",
  components: {},
  data() {
    function myValidatePhone(rule, value = "", callback) {
      if (rule.required&&value.trim() === "") {
        callback(new Error("请输入手机号码"));
      } else if (value && !/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    }
    return {
      // 页面数据
      pageDatas: {
        schoolId: "", // 学校id
        schoolName:'',//学校名称
        areaId:'',//所在地市
        schoolAddress:'',//地址
        schoolNature:'',//办公性质
        schoolClassify:'',//院校分类
        schoolStudentNum:'',//在校人数
        schoolPerson:'',//联系人
        schoolMobile:'',//联系电话
        schoolIntroduction:'',//学校内容
        schoolLogo:'',//学校封面
        schoolLogoUrl:'',//学校封面
      },
      // 办学性质 - 下拉数据
      schoolNatureList: [],
      // 院校分类 - 下拉数据
      schoolClassifyList: [],
      // 区划数据
      areatreeList: [],
      // 区划字段控制
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 页面数据 - 数据校验
      pageDatasRules: {
        schoolName: [
          { required: true, trigger: "blur", message: "请输入学校名称" },
        ],
        areaId: [
          { required: true, trigger: "change", message: "请选择所在地市" },
        ],
        schoolNature: [
          { required: true, trigger: "change", message: "请输入办公性质" },
        ],
        schoolClassify: [
          { required: true, trigger: "change", message: "请输入院校分类" },
        ],
        schoolMobile: [
          { required: false, validator: myValidatePhone, trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.pageDatas.schoolId = this.$route.query?.schoolId ?? "";
    if (this.$route.query?.schoolId) {
      this.getInfo();
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.getDictionary()
    this.editorInit();
    this.getareatree()
  },
  methods: {
    // 获取区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
     //码值获取
     getDictionary () {
      // 办学性质
      const schoolNatureList = this.$setDictionary(
        "INTERN_SCHOOL_NATURE",
        "list"
      );
      const list = [];
      for (const key in schoolNatureList) {
        list.push({
          value: key,
          label: schoolNatureList[key],
        });
      }
      this.schoolNatureList = list;
      // 院校分类
      const schoolClassifyList = this.$setDictionary(
        "INTERN_SCHOOL_CLASSIFY",
        "list"
      );
      const list1 = [];
      for (const key in schoolClassifyList) {
        list1.push({
          value: key,
          label: schoolClassifyList[key],
        });
      }
      this.schoolClassifyList = list1;
    },
    // 获取 - 学校详情
    getInfo() {
      this.$post("/biz/intern/school/info", { schoolId: this.pageDatas.schoolId })
        .then((res) => {
          this.pageDatas = {
            ...this.pageDatas,
            ...res.data
          }
          editor.txt.html(res.data?.schoolIntroduction ?? '');
        })
        .catch((err) => {
          return;
        });
    },
    // 上传封面图
    handleAvatarSuccess5(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "MATERIALS");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((res) => {
            console.log(res)
            this.pageDatas.schoolLogo = res.data.fileKey;
            this.pageDatas.schoolLogoUrl = res.data.fileURL;
          })
          .catch((err) => {
            console.log(err)
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteFilterStyle = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };

      editor.config.onchange = (html) => {
        this.pageDatas.schoolIntroduction = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
    },
    // 取消&保存
    cancelSave(cType) {
      if (cType == 1) {
        this.$refs['pageDatas'].validate((valid) => {
          if (valid) {
            let url = '';
            if (this.pageDatas.schoolId) {
              url = '/biz/intern/school/modify'
            } else {
              url = '/biz/intern/school/insert'
            }
            let cData = { ...this.pageDatas };
            this.$post(url, cData)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.$router.back();
              })
              .catch((err) => {
                return;
              });
          }
        });
      } else {
        this.$router.back();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.form-box {
  width: 800px;
  .el-input,.el-select,.el-cascader {
    width: 400px;
  }

}
</style>
  